// Libraries
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Components
import WaitlistedMessage from 'buyFlow/components/WaitlistedMessage';
import NormalMessage from 'buyFlow/components/NormalMessage';
import iconSuccess from 'images/icon-success.svg';

// Styling
import styles from './styles.module.scss';

const PaymentSuccess = ({ isWaitlisted, ...rest }) => (
  <div className={`buyflow-form ${styles.container}`}>
    <img className="buyflow-form__icon u-text-align-center" src={iconSuccess} alt="Success Icon" />
    <p className={`h4 theme-color-primary ${styles.text}`}>Your payment has been processed</p>
    {isWaitlisted ? <WaitlistedMessage {...rest} /> : <NormalMessage {...rest} />}
    <div className="buyflow-form__button-wrapper">
      <Link to="/proceeds">
        <button
          type="button"
          className={`button button--buyflow theme-background-secondary ${styles.button}`}
        >
          More about proceeds
        </button>
      </Link>
    </div>
  </div>
);

PaymentSuccess.propTypes = {
  isWaitlisted: PropTypes.bool
};

PaymentSuccess.defaultProps = {
  isWaitlisted: false
};

NormalMessage.propTypes = {
  transaction: PropTypes.string.isRequired
};

export default PaymentSuccess;
