// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { getOrderedPacks, getTotalPrice } from 'utils/tickets';

// Data
import data from 'data/data.json';

// Styling
import styles from './styles.module.css';

const WaitlistedMessage = ({
  transactionId,
  order,
  purchaserInfo: { firstName, lastName, address, city, province, postalCode }
}) => {
  const orderedPacks = getOrderedPacks(order);

  const { waitlist } = data.raffle;

  const { phoneNumber, emailAddress } = waitlist;

  return (
    <div className={styles.container} id={styles.paymentMessage}>
      <div className={styles.ticketRequest}>Here is your official ticket request:</div>
      <div>
        {'Order Information Number: '}
        <span className={styles.transactionNumber}>{transactionId}</span>
      </div>
      <table className={styles.costBreakdownTable}>
        <thead>
          <tr>
            <th className={styles.tableHeaderStyle}>Item</th>
            <th className={styles.tableHeaderStyle}>Cost per Item</th>
            <th className={styles.tableHeaderStyle}>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {orderedPacks.map(pack => (
            <tr key={pack.get('id')}>
              <th className={styles.packHeaderStyle}>
                {pack.get('description')} 
                {' '}
                {pack.get('status') === 'W' && '(On Waitlist)'}
              </th>
              <td className={styles.packHeaderStyle}>{`$${pack.get('price')}`}</td>
              <td className={styles.packHeaderStyle}>{pack.get('ordered')}</td>
            </tr>
          ))}
        </tbody>
        <tfoot className={styles.footerStyle}>
          <tr>
            <th>Grand Total</th>
            <th>{`$${getTotalPrice(orderedPacks)}`}</th>
            <th />
          </tr>
        </tfoot>
      </table>
      <div>You will receive your official ticket via email shortly.</div>
      <div>
        <div className={styles.name}>{`${firstName} ${lastName}`}</div>
        <div>{address}</div>
        <div>{`${city}, ${province} ${postalCode}`}</div>
      </div>

      <div>
        * Your official ticket request has been received. We are placing orders on a waitlist that
        will be processed in the order received. Final sale is contingent on ticket availability
        when we process your order. In the event that a ticket is available, your order will be
        processed and a ticket will be sent to you. If no more tickets are available, you will be
        advised by mail and your credit card will not be charged.
      </div>
      <div>
        * Your card will not be charged for waitlisted items unless tickets are available at the
        time we process your order.
      </div>
      {/* TODO: retrieve email and phone number from data */}
      <div>
        For all ticket inquiries, email
        {' '}
        <a className={styles.emailLink} href={`mailto:${emailAddress}`}>
          {emailAddress}
        </a>
        {' '}
        or call 
        {' '}
        {phoneNumber}
.
      </div>
    </div>
  );
};

WaitlistedMessage.propTypes = {
  transactionId: PropTypes.string.isRequired,
  order: PropTypes.shape({}).isRequired,
  purchaserInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired
  }).isRequired
};

export default WaitlistedMessage;
